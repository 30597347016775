import React, { useContext, useState } from "react";

const GlobalStateContext = React.createContext();

export function GlobalStateProvider({ children }) {
  const [globalData, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [cartNumber, setCartNumber] = useState(null);
  const [totalSitesCount, setTotalSitesCount] = useState(null);
  const [openPurchased, setOpenPurchased] = useState(false);
  const [bulkUploadData, setBulkUploadData] = useState(null);
  const updateGlobalData = (newData) => {
    setData(newData);
  };

  const updateSelectedFilters = (newSelectedFilters) => {
    setSelectedFilters(newSelectedFilters);
  };

  const updateCartNumber = (newCartNumber) => {
    setCartNumber(newCartNumber);
  };

  const updateTotalSitesCount = (newTotalSitesCount) => {
    setTotalSitesCount(newTotalSitesCount);
  };
  
  const updateOpenPurchased = (newOpenPurchased) => {
    setOpenPurchased(newOpenPurchased);
  };

  const updateUploadData = (newUploadData) => {
    setBulkUploadData(newUploadData);
  }

  return (
    <GlobalStateContext.Provider
      value={{
        globalData,
        updateGlobalData,
        selectedFilters,
        updateSelectedFilters,
        cartNumber,
        updateCartNumber,
        totalSitesCount,
        updateTotalSitesCount,
        openPurchased,
        updateOpenPurchased,
        bulkUploadData,
        updateUploadData
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
}
