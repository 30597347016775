import React from "react";
import ExcelUploader from "./BulkUpload";
import "./styles.css";

import { useGlobalState } from "../GlobalContext";
function AddXLSBulk() {
  const { bulkUploadData } = useGlobalState();
  return (
    <div className="bulkUploadContainer">
      <div className="bulkUploadHeading">Bulk Upload - Excel sheet</div>
      <div className="bulkUploadMain">
        <div className="bulkUploadInput">
          <ExcelUploader />
        </div>
        <div className="bulkDataShow">
          <div className="bulkUploadedContainer">
            <h2 className="bulkDataSSubHeading">Bulk Data Preview:</h2>
            <div className="bulkUploadedText">
              {bulkUploadData && (
                <pre>{JSON.stringify(bulkUploadData, null, 2)}</pre>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddXLSBulk;
