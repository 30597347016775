export const apiUrlPublic =
  "https://dev-listing-cpoassist.firebaseapp.com/app/public/listing/";

export const apiUrlAuthenticatedSource =
  "https://dev-listing-cpoassist.firebaseapp.com/app/listing?limit=12";
export const apiUrlAuthenticated =
  "https://dev-listing-cpoassist.firebaseapp.com/app/listing/";

export const apiQueryAuthenticated =
  "https://dev-listing-cpoassist.firebaseapp.com/app/listing?";

export const apiQueryPublic =
  "https://dev-listing-cpoassist.firebaseapp.com/app/public/listing?";

export const fetchProfileData =
  "https://dev-listing-cpoassist.firebaseapp.com/app/profiles/";

export const updateProfilePicture =
  "https://dev-listing-cpoassist.firebaseapp.com/app/profiles/upload/";

export const cart_URL =
  "https://dev-listing-cpoassist.firebaseapp.com/app/cart/";

export const addToCart_URL =
  "https://dev-listing-cpoassist.firebaseapp.com/app/cart/add/";

export const removeFromCart_URL =
  "https://dev-listing-cpoassist.firebaseapp.com/app/cart/remove/";

export const clearCart_URL =
  "https://dev-listing-cpoassist.firebaseapp.com/app/cart/clear";

export const favourite_URL =
  "https://dev-listing-cpoassist.firebaseapp.com/app/favourite";

export const emailVerification_URL =
  "https://dev-listing-cpoassist.firebaseapp.com/app/profiles/email-verification";

export const cartCheckout_URL =
  "https://dev-listing-cpoassist.firebaseapp.com/app/payments/initiate";

export const purchasedSites_URL =
  "https://dev-listing-cpoassist.firebaseapp.com/app/purchased";

export const availableFacilities = [
  "HOTEL",
  "RESTAURANT",
  "CAFE",
  "MALL",
  "SUPERMARKET",
  "RECREATION_AREA",
  "NATURE",
  "MUSEUM",
  "BIKE_SHARING",
  "BUS_STOP",
  "TAXI_STAND",
  "TRAM_STOP",
  "METRO_STATION",
  "TRAIN_STATION",
  "AIRPORT",
  "PARKING_LOT",
  "CARPOOL_PARKING",
  "FUEL_STATION",
  "WIFI",
];

export const cities = [
  "ahmedabad",
  "bangalore",
  "chennai",
  "delhi",
  "hyderabad",
  "kolkata",
  "mumbai",
  "pune",
];
