import React from "react";
import "./styles.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

function PaymentConfirmation() {
  const iconStyle = {
    color: "var(--cpotheme)",
    border: "2px solid var(--cpotheme)",
    borderRadius: "50%",
    boxShadow: "0 0 24px var(--cpotheme)",
    backgroundColor: "#fff",
    fontSize: "70px",
    position: "absolute",
    transform: "translate(150%, -50%)",
  };

  return (
    <div className="paymentSummaryContainer">
      <div className="paymentSummaryCard">
        {true ? (
          <CheckCircleRoundedIcon style={iconStyle} />
        ) : (
          <CancelRoundedIcon style={iconStyle} />
        )}
        {true ? (
          <div className="paymentSuccessMessage">Payment Successful !</div>
        ) : (
          <div className="paymentSuccessMessage">Payment Failed !</div>
        )}
        <div className="paymentHR"></div>
        <div className="yourSummary">Your Summary</div>
        <div className="paymentCompleteDetails">
          <div className="transactionID">Transaction Id:</div>
          <div className="paymentRef">Reference Id:</div>
          <div className="transactionStatus">Transaction Status: </div>
          <div className="paymentMethod">Payment Method: UPI</div>
          <div className="paymentAmount">Amount Total: </div>
        </div>
        <div className="paymentHR"></div>
        <div className="paymentsAtCPOAssist">CPO ASSIST</div>
      </div>
    </div>
  );
}

export default PaymentConfirmation;
