import React from "react";
import { Link } from "react-router-dom";
// import PositionedMenu from '../Header/MenuBar';
import logo from "../../assets/logo/logo.png";
// import SignIn from '../Authentication/Login';
// import Darkmode from '../Darkmode/Darkmode';
function SecondNavbar() {
  return (
    <div className="second-navbar">
      <div className="cpo-logo">
        <Link to="/">
          <img
            src={logo}
            alt="CPO-Assist-logo"
            className="second-navbar-logo"
          />
        </Link>
      </div>
      {/* <PositionedMenu /> */}
      {/* <div className="darkModeHidden">
              <Darkmode/>
            </div> */}
    </div>
  );
}

export default SecondNavbar;
