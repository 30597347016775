import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useGlobalState } from "../GlobalContext";
import { Link } from "react-router-dom";
import { titleCase } from "../utils/checks";
import { toast } from "react-toastify";
import { cities } from "../utils/constants";
import SearchAppBar from "./FilterSearch";
import logo from "../../assets/logo/logo.png";
import logoShort from "../../assets/logo/logoShort.png";
import ClearIcon from "@mui/icons-material/Clear";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import PositionedMenu from "./MenuBar";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import ahmedabad from "../../assets/images/Svg/Ahmedabad.svg";
import bengaluru from "../../assets/images/Svg/Bengaluru.svg";
import chennai from "../../assets/images/Svg/Chennai.svg";
import delhi from "../../assets/images/Svg/Delhi.svg";
import hyderabad from "../../assets/images/Svg/Hyderabad.svg";
import kolkata from "../../assets/images/Svg/Kolkata.svg";
import mumbai from "../../assets/images/Svg/Mumbai.svg";
import pune from "../../assets/images/Svg/Pune.svg";
// import Darkmode from "../Darkmode/Darkmode";
// import IconMenu from "../Filter/FilterMenu";
// import { fetchData } from "../../assets/sourceFile";
// import {
//   initializeOfflineHandler,
//   removeOfflineHandler,
//   storeLastLocation,
// } from "../utils/offilneHandler";
// import SignIn from "../Authentication/Login";
import TransformFiltersToQueryString from "../../assets/FIlterSortApiCalls";
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from "firebase/remote-config";
// import '../darkmode.css';
import "./styles.css";
import SignIn from "../Authentication/Signin";
import textProperties from "../utils/textProperties";

function Searchbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElType, setAnchorElType] = useState(null);
  // const [clearedAllFilters, setClearedAllFilters] = useState(null);
  const [enteredPincode, setEnteredPincode] = useState(null);
  const [myFilters, setSelectedFilters] = useState([]);
  // const { updateSelectedFilters } = useGlobalState();
  const [citiesArray, setCitiesArray] = useState([]);
  const [propertyTypesArray, setPropertyTypesArray] = useState([]);
  const { updateSelectedFilters, cartNumber, selectedFilters } =
    useGlobalState();
  const [user, setUser] = useState(null);

  const notify = (toastMessage) => {
    toast.error(toastMessage, {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 3000,
    });
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });

    return () => unsubscribe();
  }, []);
  const open = Boolean(anchorEl);
  const openType = Boolean(anchorElType);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  TransformFiltersToQueryString();
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElType(null);
  };

  const handleClickType = (event) => {
    setAnchorElType(event.currentTarget);
  };

  const remoteConfig = getRemoteConfig();

  useEffect(() => {
    // console.log(remoteConfig);
    async function fetchDataAsync() {
      try {
        await fetchAndActivate(remoteConfig);
        const configData = JSON.parse(
          getValue(remoteConfig, "siteListingFilter")._value
        );
        // const citySvgData = JSON.parse(getValue(remoteConfig, "citiesForSvg")._value);
        // console.log("citysvg:"+citySvgData);
        // console.log("Config data: " + remoteConfig);
        setCitiesArray(configData.cities);
        setPropertyTypesArray(configData.propertyTypes);
        // console.log(configData);
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    }
    fetchDataAsync();
  }, [remoteConfig]);

  function filterByCity(city) {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const cityFilterIndex = updatedFilters.findIndex((filter) =>
        filter.startsWith("cities:")
      );

      if (cityFilterIndex !== -1) {
        const cityFilter = updatedFilters[cityFilterIndex];
        let existingCities = cityFilter.split(": ")[1].split(", ");
        if (!existingCities.includes(city)) {
          existingCities.push(city);
          updatedFilters[cityFilterIndex] = `cities: ${existingCities.join(
            ", "
          )}`;
        }
      } else {
        updatedFilters.push(`cities: ${city}`);
        // console.log(updatedFilters);
      }
      updateSelectedFilters(updatedFilters);
      // console.log(updatedFilters);
      return updatedFilters;
    });

    handleClose();
  }

  function filterByType(type) {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const typeFilterIndex = updatedFilters.findIndex((filter) =>
        filter.startsWith("propertyTypes:")
      );
      if (typeFilterIndex !== -1) {
        const typeFilter = updatedFilters[typeFilterIndex];
        let existingTypes = typeFilter.split(": ")[1].split(", ");
        if (!existingTypes.includes(type)) {
          existingTypes.push(type);
          updatedFilters[
            typeFilterIndex
          ] = `propertyTypes: ${existingTypes.join(", ")}`;
        }
      } else {
        updatedFilters.push(`propertyTypes: ${type}`);
      }
      updateSelectedFilters(updatedFilters);

      return updatedFilters;
    });
    handleClose();
  }

  function filterByPincode(pincode) {
    if (/^\d{0,6}$/.test(pincode)) {
      setEnteredPincode(pincode);
      setSelectedFilters((prevFilters) => {
        const updatedFilters = [...prevFilters];
        const pincodeFilterIndex = updatedFilters.findIndex((filter) =>
          filter.startsWith("pincode:")
        );

        if (pincode.length === 6) {
          if (pincodeFilterIndex !== -1) {
            updatedFilters[pincodeFilterIndex] = `pincode: ${pincode}`;
          } else {
            updatedFilters.push(`pincode: ${pincode}`);
          }
          setTimeout(() => {
            updateSelectedFilters(updatedFilters);
          }, 500);
        }
        return updatedFilters;
      });
    }
  }

  const cityImages = {
    Ahmedabad: ahmedabad,
    Bangalore: bengaluru,
    Chennai: chennai,
    Delhi: delhi,
    Hyderabad: hyderabad,
    Kolkata: kolkata,
    Mumbai: mumbai,
    Pune: pune,
  };

  //FOR MULTIPLE PINCODES
  // function filterByPincode(pincode) {
  //   if (/^\d{0,6}$/.test(pincode)) {
  //     setEnteredPincode(pincode);
  //     setSelectedFilters((prevFilters) => {
  //       const updatedFilters = [...prevFilters];
  //       const pincodeFilterIndex = updatedFilters.findIndex((filter) =>
  //         filter.startsWith("pincode:")
  //       );

  //       if (pincode.length === 6) {
  //         if (pincodeFilterIndex !== -1) {
  //           const pincodeFilter = updatedFilters[pincodeFilterIndex];
  //           let existingPincodes = pincodeFilter.split(": ")[1].split(", ");

  //           if (!existingPincodes.includes(pincode)) {
  //             existingPincodes.push(pincode);
  //             updatedFilters[pincodeFilterIndex] = `pincode: ${existingPincodes.join(", ")}`;
  //           }
  //         } else {
  //           updatedFilters.push(`pincode: ${pincode}`);
  //         }
  //         setTimeout(() => {
  //           updateSelectedFilters(updatedFilters);
  //         }, 500);
  //       }
  //       return updatedFilters;
  //     });
  //   }
  // }

  const handleSearch = (term) => {
    filterByCity(titleCase(term));
  };

  function clearAllFilters() {
    // updateGlobalData(clearedAllFilters);
    setEnteredPincode("");
    setSelectedFilters([]);
    // updateSelectedFilters([]);
    const newLimitFilter = `limit: 12`;
    updateSelectedFilters([newLimitFilter]);
  }

  // console.log(citiesArray);

  return (
    <>
      <div className="navbar">
        <Link to="/">
          <img src={logo} alt="CPO Assist logo" className="navbar-logo" />
          <img src={logoShort} alt="CPO Assist logo" className="logoShort" />
        </Link>
        <div className="search-bar">
          <div className="selected-filters">
            {myFilters.map((filter, index) => (
              <div key={index}>{titleCase(filter.replace(/_/g, " "))}</div>
            ))}
          </div>
          <div>
            <IconButton
              sx={{
                "&:hover": {
                  // backgroundColor: "#fff",
                  color: "gray",
                },
              }}
              className="searchbar-text city-filter"
              aria-label="more"
              id="city-button"
              aria-controls={open ? "city-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              {textProperties.listingCity}
            </IconButton>
            <Menu
              id="city-menu"
              MenuListProps={{
                "aria-labelledby": "city-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="styled-menu"
              sx={{
                mt: "1rem",
                maxHeight: "25rem",
                display: "inline",
                boxShadow: "none",
                ".MuiPaper-root": {
                  borderRadius: "1rem",
                  width: "100%",
                },
              }}
              disableScrollLock={true}
            >
              <div className="horizontal-menu">
                <ul className="search-list">
                  <li className="filterSearchbar">
                    <SearchAppBar onSearch={handleSearch} value={citiesArray} />
                  </li>
                  {cities.map(
                    (
                      city //change to citiesArray for remote config
                    ) => (
                      <div
                        className="cityListClass"
                        onClick={() => filterByCity(city)}
                      >
                        {
                          <img
                            src={cityImages[titleCase(city)]}
                            alt={titleCase(city)}
                            className="svg-image"
                          />
                        }
                        <li
                          key={city}
                          className="search-item search-itemCity propertyTypeItem"
                          // onClick={() => filterByCity(city)}
                        >
                          {titleCase(city)}
                        </li>
                      </div>
                    )
                  )}
                </ul>
              </div>
            </Menu>
          </div>

          <div className="vertical-line"></div>
          <div>
            <IconButton
              sx={{
                "&:hover": {
                  // backgroundColor: "#fff",
                  color: "gray",
                },
              }}
              className="searchbar-text property-filter"
              aria-label="more"
              id="property-type-button"
              aria-controls={openType ? "property-type-menu" : undefined}
              aria-expanded={openType ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClickType}
              // style={{ fontSize: '14px' }}
            >
              {textProperties.listingPropertyType}
            </IconButton>
            <Menu
              id="property-type-menu"
              MenuListProps={{
                "aria-labelledby": "property-type-button",
              }}
              anchorEl={anchorElType}
              open={Boolean(anchorElType)}
              onClose={handleClose}
              className="styled-menu"
              sx={{
                mt: "1rem",
                maxHeight: "25rem",
                display: "inline",
                boxShadow: "none",
              }}
              disableScrollLock={true}
            >
              <div className="horizontal-menu">
                <ul className="search-list propertyTypeUL">
                  {propertyTypesArray.map((type) => (
                    <li
                      key={type}
                      className="search-item propertyTypeItem propertyTypeHover"
                      onClick={() => filterByType(type)}
                    >
                      {titleCase(type.replace(/_/g, " "))}
                    </li>
                  ))}
                </ul>
              </div>
            </Menu>
          </div>

          <div className="vertical-line"></div>
          <div className="searchbar-pincode pincode-filter">
            <TextField
              className="pincodeTextbox"
              autoComplete="on"
              id="outlined-basic"
              label={textProperties.listingPincode}
              variant="outlined"
              value={enteredPincode || ""}
              type="tel"
              onChange={(event) => filterByPincode(event.target.value)}
              inputProps={{
                autoComplete: "off",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "28px",
                  // minWidth: "6rem",
                  height: "2.5rem",
                },
                "& .MuiInputLabel-root": {
                  // margin: "-5px",
                  fontSize: "15px",
                  marginLeft: "5px",
                },
                "& .MuiInputLabel-shrink": {
                  transform: "translate(10px, 0px) scale(0.75)",
                },
              }}
            />
          </div>
          <div className="search-icon" title="Clear all filters">
            <ClearIcon onClick={() => clearAllFilters()} />
          </div>
        </div>

        <div className="profile-bar">
          {/* <div className="sign-in-nav"></div> */}
          <div className="darkmode">
            {/* <Darkmode className="darkmodeButton"/> */}
          </div>
          {user?.emailVerified ? (
            <div className="cart-icon">
              <Link to="/cart">
                <ShoppingCartRoundedIcon className="shopping-cart-icon" />
              </Link>
              {cartNumber > 0 ? (
                <span className="cart-number">{cartNumber}</span>
              ) : (
                <span className="cart-number">0</span>
              )}
            </div>
          ) : (
            <div className="cart-icon">
              {!user ? (
                <SignIn
                  value={
                    <ShoppingCartRoundedIcon
                      className="shopping-cart-icon"
                      onClick={() =>
                        notify(textProperties.notify.signinToViewCart)
                      }
                    />
                  }
                />
              ) : (
                <ShoppingCartRoundedIcon
                  className="shopping-cart-icon unverifiedCart"
                  onClick={() => notify(textProperties.notify.verifyEmail)}
                />
              )}
            </div>
          )}
          <PositionedMenu />
        </div>
      </div>
      {/* <div className="main-nav-hr"></div> */}
      {/* <SignIn value={<ShoppingCartRoundedIcon className="shopping-cart-icon" style={{color: "gray", marginTop:"5px"}} />}/> */}
      {/* <SimpleBottomNavigation /> */}
    </>
  );
}

export default Searchbar;
