import React from "react";
// import { Link } from "react-router-dom";
// import { isNumber } from "../utils/Checks";
// import Footer from "../Footer/Footer";
// import ExcelUploader from "./BulkUpload";
import "./styles.css";
import { useState } from "react";
import { apiUrlAuthenticated, availableFacilities } from "../utils/constants";
// import { processJsonData } from "./JSONBulkUpload";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import textProperties from "../utils/textProperties";
function AddSite() {
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [formImageData, setImageFormData] = useState({
    "landing-page": null,
    other: [],
  });
  const [formData, setFormData] = useState({
    name: "",
    access: "",
    siteType: "",
    parkingSupport: true,
    parkingType: 1,
    pincode: "",
    city: "",
    propertyType: "",
    area: 0,
    numberOfParking: 0,
    vehicleSuitability: [""],
    facilities: [""],
    state: "",
    locality: "",
    address: "",
    availableLoad: 0,
    views: 1,
  });

  const clearFormData = () => {
    setFormData({
      name: "",
      access: "",
      siteType: "",
      parkingSupport: true,
      parkingType: 1,
      pincode: "",
      city: "",
      propertyType: "",
      area: 0,
      numberOfParking: 0,
      vehicleSuitability: [""],
      facilities: [""],
      state: "",
      locality: "",
      address: "",
      availableLoad: 0,
      views: 1,
      // aboutSite: "",              //ADD IN BACKEND
      premiseOwnerName: "", //ADD IN BACKEND
      premiseOwnerContact: "", //ADD IN BACKEND
    });
  };

  const notify = (toastMessage) => {
    toast.error(toastMessage, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 3000,
    });
  };

  const handleNumericInput = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value);
    setFormData({ ...formData, [name]: numericValue });
    console.log(name, numericValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(name, value);
  };

  const handleInputArrayChange = (e) => {
    const { name, value } = e.target;
    let newValue = value.split(",").map((item) => item.trim());
    setFormData({ ...formData, [name]: newValue });
    console.log(name, newValue);
  };

  const handleNumericInputForPincode = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    const maxLength = 6;
    const truncatedValue = numericValue.slice(0, maxLength);
    setFormData({ ...formData, [name]: truncatedValue });
  };

  const handleAccessChange = (e) => {
    setFormData({ ...formData, access: e.target.value });
    console.log(e.target.value);
  };

  const handleImageChange = (e, fieldName) => {
    const files = e.target.files;
    const updatedFormImageData = { ...formImageData };

    if (fieldName === "landing-page") {
      updatedFormImageData["landing-page"] = files[0];
    } else if (fieldName === "other") {
      updatedFormImageData.other = [...updatedFormImageData.other, ...files];
    }

    setImageFormData(updatedFormImageData);
    console.log(updatedFormImageData);
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFacilityChange = (e) => {
    const { value, checked } = e.target;
    let updatedFacilities;

    if (checked) {
      updatedFacilities = [...selectedFacilities, value];
    } else {
      updatedFacilities = selectedFacilities.filter(
        (facility) => facility !== value
      );
    }

    setSelectedFacilities(updatedFacilities);

    setFormData({ ...formData, facilities: updatedFacilities });
    console.log(formData.facilities);
  };

  function sendFormData(formData) {
    const url = apiUrlAuthenticated;

    fetch(url, {
      method: "PUT",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Response:", data);
        sendImageData(data.data.id);
        clearFormData();
        notify(textProperties.notify.siteAdded);
      })
      .catch((error) => {
        notify(textProperties.notify.adminMandatoryFieldError);
        console.error("Error:", error);
      });
  }

  const sendImageData = async (siteId) => {
    try {
      const apiUrl = apiUrlAuthenticated + siteId + "/upload";
      console.log(apiUrl);
      if (formImageData["landing-page"]) {
        const formDataToSend = new FormData();
        formDataToSend.append("files", formImageData["landing-page"]);
        formDataToSend.append("type", "landing-page");

        const response = await fetch(apiUrl, {
          method: "POST",
          body: formDataToSend,
        });

        if (!response.ok) {
          throw new Error("Failed to upload landing page image");
        }

        console.log("Landing page image uploaded successfully!");
      }

      if (formImageData.other && formImageData.other.length > 0) {
        for (const file of formImageData.other) {
          const formDataToSend = new FormData();
          formDataToSend.append("files", file);
          formDataToSend.append("type", "other");

          const response = await fetch(apiUrl, {
            method: "POST",
            body: formDataToSend,
          });

          if (!response.ok) {
            throw new Error("Failed to upload other image");
          }

          console.log("Other images uploaded successfully!");
        }
      }
      notify(textProperties.notify.imagesUploaded);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendFormData(formData);
    console.log(formData);
  };

  return (
    <>
      <div className="home">
        {/* <div className="sidebar">
          <div className="top">
            <span className="logo">
              CPO Assist
              <AdminPanelSettingsIcon />
            </span>
          </div>
          <hr />
          <div className="center">
            <ul>
              <li className="sidebar-li">
                <PersonAddAltIcon className="icon" />
                <span>Add Site</span>
              </li>
              <li className="sidebar-li">
                <DashboardIcon className="icon" />
                <span>Dashboard</span>
              </li>
              <li className="sidebar-li">
                <Link to="/">
                  <span>CPO Assist Home</span>
                </Link>
              </li>
              </ul>
          </div> */}
        </div>
              {/* <li className="sidebar-li">
                <DriveFolderUploadIcon className="icon" />
                <span>Bulk Upload</span>
              </li> */}
              {/* <li>
                    <IntegrationInstructionsIcon className='icon'/>
                    <span>Logs</span>
                </li>
                <li>
                    <PaymentIcon className='icon'/>
                    <span>Orders</span>
                </li>
                <li>
                    <SettingsApplicationsIcon className='icon'/>
                    <span>Settings</span>
                </li>
                <li>
                    <AccountBoxIcon className='icon'/>
                    <span>Profile</span>
                </li>
                <li>
                    <LogoutIcon className='icon'/>
                    <span>Logout</span>
                </li> */}

        <div className="home-container">
          <div>
            <div className="adminHeading">
            Add Site Data
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="column">
                  <label>
                    Site Name:
                    <input
                      type="text"
                      className="special-input"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="column">
                  <label>
                    City:
                    <input
                      type="text"
                      className="special-input"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="column">
                  <label>
                    State:
                    <input
                      type="text"
                      className="special-input"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <label>
                    Locality:
                    <input
                      type="text"
                      className="special-input"
                      name="locality"
                      value={formData.locality}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="column">
                  <label>
                    Site Type:
                    <input
                      type="text"
                      className="special-input"
                      name="siteType"
                      value={formData.siteType}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="column">
                  <label>
                    Number of Parking:
                    <input
                      type="number"
                      className="special-input"
                      name="numberOfParking"
                      value={formData.numberOfParking}
                      onChange={handleNumericInput}
                    />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label>
                    Pincode:
                    <input
                      type="text"
                      name="pincode"
                      className="special-input"
                      value={formData.pincode}
                      onChange={handleNumericInputForPincode}
                    />
                  </label>
                </div>
                <div className="column">
                  <label>
                    Access:
                    <select
                      name="access"
                      value={formData.access}
                      onChange={handleAccessChange}
                    >
                      <option value="">Select Access Type</option>
                      <option value="Public">Public</option>
                      <option value="Captive">Captive</option>
                      <option value="Hybrid">Hybrid</option>
                      <option value="Restricted">Restricted</option>
                    </select>
                  </label>
                </div>

                <div className="column">
                  <label>
                    Suitable for:
                    <select
                      name="vehicleSuitability"
                      value={formData.vehicleSuitability[3]}
                      onChange={handleInputArrayChange}
                    >
                      <option value="">Suitable for</option>
                      <option value="2W">2W</option>
                      <option value="3W">3W</option>
                      <option value="4W">4W</option>
                      <option value="2W, 3W">2W, 3W</option>
                      <option value="2W, 4W">2W, 4W</option>
                      <option value="3W, 4W">3W, 4W</option>
                      <option value="2W, 3W, 4W">2W, 3W, 4W</option>
                    </select>
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <div className="dropdown">
                    <label
                      onClick={handleToggleDropdown}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Facilities: <ArrowDropDownIcon />{" "}
                    </label>
                    {isOpen && (
                      <div className="checkbox-options">
                        {availableFacilities.map((facility) => (
                          <label key={facility}>
                            <input
                              type="checkbox"
                              value={facility}
                              checked={selectedFacilities.includes(facility)}
                              onChange={handleFacilityChange}
                            />
                            {facility}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="column">
                  <label>
                    Available Load (kW):
                    <input
                      type="number"
                      name="availableLoad"
                      value={formData.availableLoad}
                      onChange={handleNumericInput}
                    />
                  </label>
                </div>

                <div className="column">
                  <label>
                    Available Area (Sq. M):
                    <input
                      type="number"
                      name="area"
                      value={formData.area}
                      onChange={handleNumericInput}
                    />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label>
                    Address:
                    <textarea
                      type="text"
                      className="special-input"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                {/* <div className="column">
                  <label>
                    About site:
                    <textarea
                      type="text"
                      className="special-input"
                      name="aboutSite"
                      value={formData.aboutSite}
                      onChange={handleInputChange}
                    />
                  </label>
                </div> */}
              </div>
              <div className="row">
                <div className="column">
                  <label>
                    Owner's Name:
                    <input
                      type="text"
                      className="special-input"
                      name="premiseOwnerName"
                      value={formData.premiseOwnerName}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="column">
                  <label>
                    Owner's Contact:
                    <input
                      type="number"
                      name="premiseOwnerContact"
                      value={formData.premiseOwnerContact}
                      onChange={handleNumericInput}
                    />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label>
                    Listing Page Image
                    <input
                      type="file"
                      accept="image/*"
                      alt="listing-page-image"
                      name="landing-page"
                      onChange={(e) => handleImageChange(e, "landing-page")}
                    />
                  </label>
                </div>

                <div className="column">
                  <label>
                    Other Images (Add atleast 4 images)
                    <input
                      type="file"
                      accept="image/*"
                      alt="charge-location-images"
                      name="other"
                      multiple
                      onChange={(e) => handleImageChange(e, "other")}
                    />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <button type="submit" className="adminSubmitBtn">
                    Submit
                  </button>
                </div>
                <div className="column">
                  <button
                    onClick={clearFormData}
                    type="button"
                    className="clear-form-data"
                  >
                    Clear All
                  </button>
                </div>
                <div className="column">
                  {/* Bulk Upload: <ExcelUploader /> */}
                </div>
                <div className="column">
                  {/* <button onClick={processJsonData}>JSON Bulk uploader</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      {/* </div> */}
      {/* <Footer /> */}
    </>
  );
}

export default AddSite;
