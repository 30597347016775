// import React from "react";
// import * as XLSX from "xlsx";
// // import {processJSONData} from "./JSONBulkUpload";
// function ExcelUploader() {
//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];

//     const reader = new FileReader();

//     reader.onload = (e) => {
//       const data = new Uint8Array(e.target.result);
//       const workbook = XLSX.read(data, { type: "array" });
//       const firstSheetName = workbook.SheetNames[0];
//       const firstSheetData = XLSX.utils.sheet_to_json(
//         workbook.Sheets[firstSheetName]
//       );

//       console.log("Transformed Data:", firstSheetData);

//       // Save as JSON file
//       const jsonData = JSON.stringify(firstSheetData, null, 2);
//       const blob = new Blob([jsonData], { type: "application/json" });
//       // const fileName = 'excelData.json';
//       const currentDate = new Date();
//       // console.log(currentDate);
//       const formattedDate = currentDate.toLocaleDateString("en-US", {
//         year: "numeric",
//         month: "2-digit",
//         day: "2-digit",
//       });

//       const fileName = `CPOA-${formattedDate}-SiteData.json`;

//       // Create a link and trigger a click event to download the JSON file
//       const link = document.createElement("a");
//       link.href = URL.createObjectURL(blob);
//       link.download = fileName;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);

//       setTimeout(() => {
//         // processJSONData(jsonData);
//         console.log(firstSheetData);
//       }, 1000);
//     };
//     reader.readAsArrayBuffer(file);
//   };

//   return (
//     <div className="bulk-upload">
//       Bulk upload:
//       <input type="file" accept=".xls, .xlsx" onChange={handleFileUpload}/>
//     </div>
//   );
// }

// export default ExcelUploader;

import React, { useState } from "react";
import * as XLSX from "xlsx";
import { useGlobalState } from "../GlobalContext";
function ExcelUploader() {
  // const [convertedData, setConvertedData] = useState(null);
  const { updateUploadData } = useGlobalState();
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const firstSheetData = XLSX.utils.sheet_to_json(
        workbook.Sheets[firstSheetName]
      );

      console.log("Transformed Data:", firstSheetData);

      // Save as JSON file
      const jsonData = JSON.stringify(firstSheetData, null, 2);
      const blob = new Blob([jsonData], { type: "application/json" });
      // const fileName = 'excelData.json';
      const currentDate = new Date();
      // console.log(currentDate);
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const fileName = `CPOA-${formattedDate}-SiteData.json`;

      // Set the converted data to state
      // setConvertedData(firstSheetData);
      updateUploadData(firstSheetData);
      // Create a link and trigger a click event to download the JSON file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="bulk-upload">
      Bulk upload:
      <input type="file" accept=".xls, .xlsx" onChange={handleFileUpload} />
    </div>
  );
}

export default ExcelUploader;
