import { getAuth } from "firebase/auth";
import { cart_URL } from "../utils/constants";

export async function cartAction(siteId) {
  const auth = getAuth();
  const user = auth.currentUser;

  try {
    let apiUrl = cart_URL + siteId;
    const refreshedUser = await user.getIdToken();

    const headers = {
      Authorization: `Bearer ${refreshedUser}`,
      accept: "application/json",
      "Content-Type": "application/json",
    };

    const response = await fetch(apiUrl, {
      method: "POST",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed cart action");
    }

    // console.log("Cart cleared successfully");
  } catch (error) {
    console.error("Error cart action:", error);
    throw error;
  }
}
