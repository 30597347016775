import React from "react";

function ActivityLogs() {
  return (
    <div className="activityLogsContainer">
      <div className="activityLogsHeading">Activity Logs</div>
    </div>
  );
}

export default ActivityLogs;
