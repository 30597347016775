import React, { useEffect, useState } from "react";
import "./styles.css";
// import DashboardIcon from "@mui/icons-material/Dashboard";
import ApprovalIcon from "@mui/icons-material/Approval";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import HomeIcon from "@mui/icons-material/Home";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Link } from "react-router-dom";
import Sidebar from "./AddSite";
import Drafts from "./Drafts";
import Dashboard from "./Dashboard/Dashboard";
import AddXLSBulk from "./AddXLSBulk";
import ActivityLogs from "./ActivityLogs";
function AdminPortal() {
  const [page, setPage] = useState("");

  useEffect(() => {
    setPage("Dashboard");
  }, []);

  const gotoAddSite = (name) => {
    setPage(name);
  };

  return (
    <div className="home">
      <div className="sidebar">
        <div className="top">
          <span className="logo">
            CPO Assist
            <AdminPanelSettingsIcon />
          </span>
        </div>
        <hr />
        <div className="center">
          <ul>
            <li className="sidebar-li" onClick={() => gotoAddSite("AddSite")}>
              <span>Add Site</span>
              <PersonAddAltIcon className="icon" />
            </li>
            <li
              className="sidebar-li"
              onClick={() => gotoAddSite("BulkUpload")}
            >
              <span>Bulk Upload</span>
              <DriveFolderUploadIcon className="icon" />
            </li>
            <li className="sidebar-li" onClick={() => gotoAddSite("Dashboard")}>
              <span>Approvals</span>
              <ApprovalIcon className="icon" />
            </li>
            <li className="sidebar-li" onClick={() => gotoAddSite("Drafts")}>
              <span>Drafts</span>
              <DraftsIcon className="icon" />
            </li>
            <li
              className="sidebar-li"
              onClick={() => gotoAddSite("ActivityLogs")}
            >
              <span>ActivityLogs</span>
              <IntegrationInstructionsIcon className="icon" />
            </li>
            <li className="sidebar-li">
              <Link to="/">
                <span>CPO Assist Home</span>
              </Link>
              <HomeIcon className="icon" />
            </li>
            {/* <li>
                <PaymentIcon className='icon'/>
                <span>Orders</span>
            </li>
            <li>
                <SettingsApplicationsIcon className='icon'/>
                <span>Settings</span>
            </li>
            <li>
                <AccountBoxIcon className='icon'/>
                <span>Profile</span>
            </li>
            <li>
                <LogoutIcon className='icon'/>
                <span>Logout</span>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="home-container">
        {page === "AddSite" && <Sidebar />}
        {page === "Drafts" && <Drafts />}
        {page === "Dashboard" && <Dashboard />}
        {page === "BulkUpload" && <AddXLSBulk />}
        {page === "ActivityLogs" && <ActivityLogs />}
      </div>
    </div>
  );
}

export default AdminPortal;
