import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
// import { useNavigate } from 'react-router-dom';

function LogoutButton() {
  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth); // Sign the user out
      // You can optionally redirect the user to a different page here
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <span onClick={handleLogout}>Logout</span>
  );
}

export default LogoutButton;
