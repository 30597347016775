import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./styles.css";
function FAQ() {
  return (
    <>
    <Header />
    <div className="faqContainer">
      <div className="faqHeading">FAQs</div>
      <div className="faqSubHeading">CPO</div>
      <div className="faqCpo">
        <h3>1. Can I view properties without subscription/paying for it?</h3>
        <p>
          Yes, you can search and view basic details of the properties without
          subscription. But to receive complete details of the property and to
          get connected with the owner you will have to subscribe and pay
          separately for viewing.
        </p>
        <h3>
          2. Didn't find property according to my requirement, what should i do?
        </h3>
        <p>
          Ask for a detailed requirement form available in the Profile section
          and fill in the details as per your requirements. Our team will
          respond shortly after.
        </p>
        <h3>
          3. Does CPO Assist help me in documents and permissions required for
          Charger Installation?
        </h3>
        <p>
          Yes, CPO Assist helps the CPO in the process of getting their
          documents verified. CPO Assist has a vast network of DISCOMs and LOAs
          which helps in making the process hassle free
        </p>
        <h3>
          4. What if I don’t like the property after paying for getting it’s
          details?{" "}
        </h3>
        <p>
          The cost of Viewing Site is set to a minimal amount keeping in mind
          the same.
        </p>
        <h3>
          5. Will CPO Assist be responsible for any mismatch in actual site and
          the listed site?
        </h3>
        <p>
          CPO Assist verifies and an agent makes a on-site visit to prepare a
          detailed SIte report which covers every aspect of the site, which is
          again verified by our internal team.
        </p>
        <h3>6. How soon can I get the site ready for charger installation?</h3>
        <p>
          Usually a CPO can get the site available as per the possession time
          mentioned by the Land Owner.
        </p>
        <h3>7. What if I want to customise a site as per my needs?</h3>
        <p>
          As CPO Assist acts as a bridge between CPOs and LOAs, any additional
          requests can be discussed by the Land Owner directly.
        </p>
      </div>
      <div className="faqSubHeading">LOA</div>
      <div className="faqLoa faqCpo">
        <h3>1. Why is my site not listed yet?</h3>
        <p>
          CPO Assist acts as an auditor and verifies every minute detail about
          the site. After verification the site is made eligible for listing.
        </p>
        <h3>2. Why is my site visited before listing?</h3>
        <p>
          CPO Assist makes sure every detail about the site is accurate as per
          uploaded documents, photos, location and insights provided by the LOA
          and a Site Report is generated after visiting the site.
        </p>
        <h3>3. Why is Site Report necessary?</h3>
        <p>
          SIte Report is necessary for checking the authenticity of the Site.
        </p>
        <h3>4. Do I need to pay a premium before listing my site?</h3>
        <p>
          Yes there is a minimal cost for subscribing to the CPO Assist portal.
        </p>
        <h3>5. How can I make sure the CPO proposal is authentic?</h3>
        <p>
          Every CPO that registers to the platform is email and phone
          number-verified before it is able to look for sites and make
          proposals.
        </p>
        <h3>
          6. Can I list more than one site in a different city and do I have to
          pay a premium for the same?
        </h3>
        <p>
          Yes you can list multiple sites in multiple cities. No, there is no
          premium charged on listing more than one site.
        </p>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default FAQ;
