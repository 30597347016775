import React from "react";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import "./styles.css";
import textProperties from "../utils/textProperties";
import X from "../../assets/logo/twitter.png";
function Footer() {
  const [expanded, setExpanded] = React.useState(false);

  const toggleFooter = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={`footer-accordion ${expanded ? "expanded" : ""}`}>
        <div
          className={`expand-button ${expanded ? "hidden" : ""}`}
          onClick={toggleFooter}
        >
          <ExpandLessIcon className="expandIcon" />
        </div>
        <div
          className={`close-button ${expanded ? "" : "hidden"}`}
          onClick={toggleFooter}
        >
          <ExpandMoreIcon className="expandIcon" />
        </div>
        <div className="footer-content">
          <div className="upper-footer">
            <div className="popular-searches">
              <h3>Useful Links</h3>
              <ul>
                <Link to="/faq" target="blank">
                  <li className="footerFaq">FAQs</li>
                </Link>
                <li>Property for Sale</li>
                <li>Reasle Properties</li>
                <li>Recent Properties</li>
              </ul>
            </div>
            <div className="quick-links">
              <h3>Quick Links</h3>
              <ul>
                <li>Property for Rent</li>
                <li>Property for Sale</li>
                <li>Reasle Properties</li>
                <li>Recent Properties</li>
              </ul>
            </div>
            <div className="contact-us">
              <h3>Contact Us</h3>
              <ul>
                <li>Property for Rent</li>
                <li>Property for Sale</li>
                <li>Reasle Properties</li>
                <li>Recent Properties</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="hr-line"></div>
      <div className="footer">
        <div className="footer-left">
          <span className="footerCPO">{textProperties.copyRightCPO}</span>
          <span className="footerPrivacy">
            {" "}
            · {textProperties.privacyTerms} · {textProperties.companyDetails}
          </span>
        </div>
        <div className="footer-right">
          <p className="last-hr-line"></p>
          <Link
            to={textProperties.CPO_FB_URL}
            target="blank"
            title={textProperties.facebook}
          >
            <FacebookIcon sx={{ color: "#3b5998" }} />
          </Link>
          <Link
            to={textProperties.CPO_LINKEDIN_URL}
            target="blank"
            title={textProperties.linkedin}
          >
            <LinkedInIcon sx={{ color: "#0e76a8" }} />
          </Link>
          <Link
            to={textProperties.CPO_INSTAGRAM_URL}
            target="blank"
            title={textProperties.instagram}
          >
            <InstagramIcon sx={{ color: "#d62976" }} />
          </Link>
          <Link
            to={textProperties.CPO_X_URL}
            target="blank"
            title={textProperties.x}
          >
            <img src={X} alt="X.com" className="Xlogo" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default Footer;
