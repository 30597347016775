function isNumber(input) {
  if (typeof input === "number") {
    return true;
  } else if (typeof input === "string") {
    return Number.isInteger(Number(input));
  }
  return false;
}

function isString(input) {
  return typeof input === "string" && input.trim() !== "";
}

function isAlphabetical(input) {
  return /^[A-Za-z]*$/.test(input);
}

function isStringOrArray(input) {
  return (
    (typeof input === "string" && input.trim() !== "") ||
    (Array.isArray(input) && input.length > 0)
  );
}

function titleCase(str) {
  if (str && typeof str === "string" && str.length > 0) {
    if (str.includes("RWAs")) {
      return str;
    }
    if (str.includes("IT PARKS")) {
      return "IT Parks";
    }
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
}

const na = /^(n\/a|N\/A|na|NA)$/;

const isPanNumberValid = (panNumber) => {
  const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/;
  return panRegex.test(panNumber) || na.test(panNumber);
};

const isGSTNumberValid = (gstNumber) => {
  const gstRegex =
    /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}$/;
  return gstRegex.test(gstNumber) || na.test(gstNumber);
};

const isMobileNumberValid = (mobileNumber) => {
  const mobileRegex = /^[6789]\d{9}$/;
  return mobileRegex.test(mobileNumber);
};

const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isCompanyEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) || na.test(email);
};

const isNameValid = (name) => {
  const firstNameRegex = /^[A-Za-z]{1,25}$/;
  return firstNameRegex.test(name);
};

function isNumberOrFloat(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

function isValidIndianPhoneNumber(number) {
  // Regular expression for a valid Indian telephone or mobile number
  const regex = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?((?:[2-9]\d{1,3}\s*[-]\s*\d{6,10})|(?:[6789]\d{9}))$/;

  // Test the number against the regular expression
  return regex.test(number);
}

export {
  isNumber,
  isString,
  isStringOrArray,
  titleCase,
  isAlphabetical,
  isMobileNumberValid,
  isEmailValid,
  isNameValid,
  isPanNumberValid,
  isGSTNumberValid,
  isCompanyEmailValid,
  isNumberOrFloat,
  isValidIndianPhoneNumber,
};
