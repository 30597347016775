import { getAuth } from "firebase/auth";
import { fetchProfileData } from "../utils/constants";

export  function updateProfileData(profileData) {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log(profileData);
    async function updateProfile() {
      try {
        let apiUrl = fetchProfileData;

        const refreshedUser = await user.getIdToken(/* forceRefresh */ true);
        const headers = {
          Authorization: `Bearer ${refreshedUser}`,
          accept: "application/json",
          "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers,
          body: JSON.stringify(profileData),
        });

        if (!response.ok) {
          throw new Error("Failed to update data");
        }
        console.log("Profile updated successfully!");
        window.location.reload();
      } catch (error) {
        console.error("Error updating data:", error);
        throw error;
      }
    }
    updateProfile();
  }
