import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

export default function SearchBar({ onSearch, value }) {
  const [cityValue, setCityValue] = useState("");
  const cities = value;
  const searchCity = (event, value) => {
    if (value && value.trim() !== "" && cities && cities.includes(value)) {
      setCityValue(value);
      onSearch(value);
    }
  };

  const handleKeyDown = (event) => {
    const lowercasedValue = event.target.value.toLowerCase();
    if (
      event.key === "Enter" &&
      cities &&
      cities.map((city) => city.toLowerCase()).includes(lowercasedValue)
    ) {
      onSearch(event.target.value); // Send original case to onSearch
    }
  };

  useEffect(() => {
    setCityValue(""); // Reset the input value when cities change
  }, [cities]);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <AppBar position="static" style={{ backgroundColor: "#ea5355" }}>
        <Toolbar>
          <Autocomplete
            value={cityValue}
            onChange={searchCity}
            options={cities || []}
            className="citySearchInput"
            isOptionEqualToValue={(option, value) =>
              option.toLowerCase() === value.toLowerCase()
            }
            renderInput={(params) => (
              <Search>
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  fullWidth
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      color: "white", // Style for the input text
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "white" }, // Style for the label text
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        // borderColor: 'white', // Border color when not focused
                      },
                      '&:hover fieldset': {
                        borderColor: 'white', // Border color on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'white', // Border color when focused
                      }
                    }
                  }}
                />
              </Search>
            )}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
