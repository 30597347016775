import React from "react";
import "./ShimmerListingPage.css"; // Create a corresponding CSS file

const Shimmer = () => {
  return (
    <>
      <div className="shimmer-header"></div>
    <div className="shimmer-container">

      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-addtocart"></div>
      </div>
      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-addtocart"></div>
      </div>
      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-addtocart"></div>
      </div>
      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
      <div className="shimmer-addtocart"></div>
      </div>
      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
      <div className="shimmer-addtocart"></div>
      </div>

      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-addtocart"></div>
      </div>
      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-addtocart"></div>
      </div>
      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-addtocart"></div>
      </div>
      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
      <div className="shimmer-addtocart"></div>
      </div>
      <div className="shimmer-card">
        <div className="shimmer-image"></div>
        <div className="shimmer-text"></div>
      <div className="shimmer-addtocart"></div>
      </div>
    </div>
      </>
  );
};

export default Shimmer;
