import React from "react";
import { Modal } from "@mui/material";
import LoadingGif from "../../assets/images/cpoassit.gif";
import "./utilStyles.css";

function LoadingModal({ open, onClose, text }) {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="loadingModalDiv">
        <img src={LoadingGif} alt="Loading..." className="loadingModalImage" />
        <span className="loadingModalText">{text}</span>
      </div>
    </Modal>
  );
}

export default LoadingModal;
