import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  isNumber,
  isString,
  isStringOrArray,
  titleCase,
  isNumberOrFloat,
} from "../utils/checks";
import { apiUrlAuthenticated, apiUrlPublic } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { cartAction } from "../Cart/cartActions";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { favouriteActions } from "../Favourites/favouriteActions";
import textProperties from "../utils/textProperties";
import CPOImage from "../../assets/images/emptycart.jpeg";
import NestedModal from "./Modal";
import Button from "@mui/material/Button";
import Footer from "../Footer/Footer";
import Shimmer from "../Shimmer/ShimmerProductDetailsPage";
import Header from "../Header/Header";
import Carousel from "react-material-ui-carousel";
import StarIcon from "@mui/icons-material/Star";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import SignIn from "../Authentication/Signin";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import "./siteStyles.css";
export default function ProductDetails() {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const [product, setProduct] = useState(null);
  const [user, setUser] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);
  const [favChanged, setFavChanged] = useState(true);

  const auth = getAuth();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
      fetchData();
    });

    return () => unsubscribe();
  }, []);

  async function getRefreshedToken() {
    try {
      const user = auth.currentUser;
      if (user) {
        const refreshedToken = await user.getIdToken();
        return refreshedToken;
      }
      return null;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }

  async function fetchData() {
    try {
      const user = auth.currentUser;
      let apiUrl = apiUrlPublic + siteId;
      const refreshedToken = await getRefreshedToken();
      // console.log(apiUrl);
      // console.log(user);
      if (user && user?.emailVerified) {
        apiUrl = apiUrlAuthenticated + siteId;
      }
      const headers = new Headers({
        accept: "application/json",
        Authorization: `Bearer ${refreshedToken}`,
      });

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: user ? headers : undefined,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const { data } = await response.json();
      setProduct(data);
      // setMainImage(selectedProduct?.listingPageImage);
      setImageLoading(false);
      // console.log(data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  //   useEffect(() => {
  //   fetchData();
  // }, [siteId, user]);

  function addItemToCart(siteId) {
    cartAction(`add/${siteId}`);
    setTimeout(() => {
      navigate("/cart");
    }, 1000);
  }

  let images = [CPOImage];

  if (user) {
    images = [
      CPOImage,
      "https://images.unsplash.com/photo-1617886322168-72b886573c35?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGV2JTIwY2hhcmdpbmd8ZW58MHx8MHx8fDA%3D",
      "https://images.unsplash.com/photo-1607171028974-319ba56cb013?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGV2JTIwY2hhcmdpbmd8ZW58MHx8MHx8fDA%3D",
      "https://images.unsplash.com/photo-1592919365284-a57ad163e07a?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZXYlMjBjaGFyZ2VyfGVufDB8fDB8fHww",
      "https://images.unsplash.com/photo-1671785120538-c24cbe823ccc?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGV2JTIwY2hhcmdlfGVufDB8fDB8fHww",
    ];
  }

  let toastNum = true;
  const notify = (toastMessage) => {
    if (toastNum) {
      toast.error(toastMessage, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
      });
      toastNum = false;
      setInterval(() => {
        toastNum = true;
      }, 7000);
    }
  };

  const handleAddToFavourite = (id) => {
    console.log("Button Clicked!");
    favouriteActions(`/add/${id}`);
    fetchData();
  };

  const handleRemoveFromFavourite = (id) => {
    console.log("Button Clicked!");
    favouriteActions(`/remove/${id}`);
    fetchData();
  };

  return (
    <div className="siteDetailsPage">
      <ToastContainer />
      <Header />
      {product ? (
        <>
          <div className="pageHeading">
            <div className="siteName">
              {isString(product?.name) ? (
                <h1 className="siteNameH1">{product?.name}</h1>
              ) : (
                <h1 className="siteNameH1">{textProperties.siteName}</h1>
              )}
            </div>
            <div className="mobileSubHeading">
              {" "}
              <h3
                className="ratingProductDetails"
                style={{ fontWeight: "bold" }}
              >
                <StarIcon style={{ fontSize: "18px", marginBottom: "-3px" }} />{" "}
                4.3
              </h3>
              <div className="mobileWishlist">
                {user?.emailVerified ? (
                  <div className="wishlistIcon">
                    {product?.favourite ? (
                      <div
                        className="favouriteSavedTextMobile"
                        onClick={() => handleRemoveFromFavourite(product?.id)}
                      >
                        <FavoriteRoundedIcon
                          style={{ fontSize: "21px", color: "#ea5355" }}
                        />
                        <span className="saveSpan">Saved</span>
                      </div>
                    ) : (
                      <div
                        className="favouriteSavedTextMobile"
                        onClick={() => handleAddToFavourite(product?.id)}
                      >
                        <FavoriteBorderRoundedIcon
                          style={{ fontSize: "21px" }}
                        />
                        <span className="saveSpan">Save</span>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {user ? (
                      <FavoriteBorderRoundedIcon
                        style={{ color: "black" }}
                        onClick={() =>
                          notify(textProperties.notify.verifyEmail)
                        }
                      />
                    ) : (
                      <SignIn
                        value={
                          <FavoriteBorderRoundedIcon
                            style={{ color: "black" }}
                            onClick={() =>
                              notify(textProperties.notify.signinFirst)
                            }
                          />
                        }
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="pageImages">
            <div className="mainImage">
              <h3
                className="ratingProductDetails"
                style={{ fontWeight: "bold" }}
              >
                <StarIcon style={{ fontSize: "18px", marginBottom: "-3px" }} />{" "}
                4.3
              </h3>{" "}
              <NestedModal
                // imageURL={product?.mainImage}
                imageURL={CPOImage}
                classType={"mainImage"}
                altType={product?.name}
                imageName={"Main Image"}
              />
            </div>
            {images ? (
              <div className="mobileSliderBtnContainer">
                <Carousel className="carouselmobile">
                  {images.map((image, index) => (
                    <img
                      src={image}
                      alt={index}
                      key={index}
                      className="mobileViewImage"
                    />
                  ))}
                </Carousel>
              </div>
            ) : null}
            {user?.emailVerified ? (
              <>
                <div className="subImages1">
                  <span style={{ color: "white", padding: "2px" }}>{"."}</span>
                  <span className="subimg11">
                    <NestedModal
                      // imageURL={product?.entryImage}
                      imageURL={
                        "https://images.unsplash.com/photo-1617886322168-72b886573c35?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGV2JTIwY2hhcmdpbmd8ZW58MHx8MHx8fDA%3D"
                      }
                      classType={"subimg11"}
                      altType={product?.name}
                      imageName={"Entry Image"}
                    />
                  </span>
                  <span className="subimg12">
                    <NestedModal
                      // imageURL={product?.exitImage}
                      imageURL={
                        "https://images.unsplash.com/photo-1607171028974-319ba56cb013?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGV2JTIwY2hhcmdpbmd8ZW58MHx8MHx8fDA%3D"
                      }
                      classType={"subimg12"}
                      altType={product?.name}
                      imageName={"Exit Image"}
                    />
                  </span>
                </div>
                <div className="subImages2">
                  <div className="wishlistIcon">
                    {product?.favourite ? (
                      <div
                        className="favouriteSavedText"
                        onClick={() => handleRemoveFromFavourite(product?.id)}
                      >
                        <FavoriteRoundedIcon
                          style={{ fontSize: "21px", color: "#ea5355" }}
                          // onClick={() => handleRemoveFromFavourite(product?.id)}
                        />
                        <span className="saveSpan">Saved</span>
                      </div>
                    ) : (
                      <div
                        className="favouriteSavedText"
                        onClick={() => handleAddToFavourite(product?.id)}
                      >
                        <FavoriteBorderRoundedIcon
                          style={{ fontSize: "21px" }}
                          // onClick={() => handleAddToFavourite(product?.id)}
                        />
                        <span className="saveSpan">Save</span>
                      </div>
                    )}
                  </div>
                  <span className="subimg21">
                    <NestedModal
                      // imageURL={product?.chargeLocationImage}
                      imageURL={
                        "https://images.unsplash.com/photo-1592919365284-a57ad163e07a?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZXYlMjBjaGFyZ2VyfGVufDB8fDB8fHww"
                      }
                      classType={"subimg21"}
                      altType={product?.name}
                      imageName={"Charge Location Image"}
                    />
                  </span>
                  <span className="subimg22">
                    <NestedModal
                      // imageURL={product?.chargerImage}
                      imageURL={
                        "https://images.unsplash.com/photo-1671785120538-c24cbe823ccc?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGV2JTIwY2hhcmdlfGVufDB8fDB8fHww"
                      }
                      classType={"subimg22"}
                      altType={product?.name}
                      imageName={"Charger Image"}
                    />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="subImages1">
                  <span style={{ color: "white", padding: "2px" }}>{"."}</span>
                  <span className="subimg11">
                    <img
                      src="https://images.unsplash.com/photo-1617886322168-72b886573c35?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGV2JTIwY2hhcmdpbmd8ZW58MHx8MHx8fDA%3D"
                      alt="Login to view"
                      className="subimg11 blurred"
                    />
                  </span>
                  <span className="subimg12">
                    <img
                      src="https://images.unsplash.com/photo-1607171028974-319ba56cb013?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGV2JTIwY2hhcmdpbmd8ZW58MHx8MHx8fDA%3D"
                      alt="Login to view"
                      className="subimg12 blurred"
                    />
                  </span>
                </div>
                <div className="subImages2">
                  <div className="wishlistIcon">
                    {user ? (
                      <>
                        <FavoriteBorderRoundedIcon
                          style={{
                            color: "black",
                            fontSize: "21px",
                          }}
                          onClick={() =>
                            notify(textProperties.notify.verifyEmail)
                          }
                        />
                        <span className="saveSpan">Save</span>
                      </>
                    ) : (
                      <>
                        <SignIn
                          value={
                            <FavoriteBorderRoundedIcon
                              style={{
                                color: "black",
                                fontSize: "21px",
                              }}
                              onClick={() =>
                                notify(textProperties.notify.signinFirst)
                              }
                            />
                          }
                        />
                        <span className="saveSpan">Save</span>
                      </>
                    )}
                  </div>
                  <span className="subimg21">
                    <img
                      src="https://images.unsplash.com/photo-1592919365284-a57ad163e07a?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZXYlMjBjaGFyZ2VyfGVufDB8fDB8fHww"
                      alt="Login to view"
                      className="subimg21 blurred"
                    />
                  </span>
                  <span className="subimg22">
                    <img
                      src="https://images.unsplash.com/photo-1671785120538-c24cbe823ccc?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGV2JTIwY2hhcmdlfGVufDB8fDB8fHww"
                      alt="Login to view"
                      className="subimg22 blurred"
                    />
                  </span>
                  {!user && (
                    <span className="loginToView">
                      {textProperties.signInToView}
                    </span>
                  )}
                  {user && (
                    <span className="loginToView verifyToView">
                      {textProperties.verifyEmailToView}
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="siteDetailL1">
            <div className="siteMultipleDetails">
              {product?.ownerName || product?.ownerContact ? (
                <div className="ownerNameNumberMobile">
                  <div className="purchasedMobile">
                    <span className="purchasedLogoMobile">Purchased</span>
                  </div>
                  <span className="ownerDetails ownerDetailsMobile">
                    Owner Details:
                  </span>
                  <span>
                    Name:{" "}
                    {product?.ownerName ? product.ownerName : "Unavailable"}
                  </span>
                  <span>
                    Contact:{" "}
                    {product?.ownerContact
                      ? product.ownerContact
                      : "Unavailable"}
                  </span>
                </div>
              ) : null}
              <div className="siteAddress">
                {isString(product?.city) ? (
                  <h2
                    className="siteAddressH2"
                    title={`City: ${product?.city}`}
                  >
                    {product?.city}
                  </h2>
                ) : (
                  <h2 className="siteAddressH2">Unavailable</h2>
                )}
                {isString(product?.address) ? (
                  <span
                    className="addressDetails"
                    title={`Address: ${product?.address}`}
                  >
                    {product?.address}
                  </span>
                ) : null}
                <div className="siteAddressDetails">
                  {isString(product?.state) ? (
                    <span
                      className="addressDetails"
                      title={`State: ${product?.state}`}
                    >
                      {product?.state}
                    </span>
                  ) : null}
                  {/* {" · "} */}
                  {isString(product?.locality) ? (
                    <span
                      className="addressDetails"
                      title={`Locality: ${product?.locality}`}
                    >
                      {product?.locality}
                    </span>
                  ) : null}
                  {/* {" · "} */}

                  {isNumber(product?.pincode) ? (
                    <span
                      className="addressDetails"
                      title={`Pincode: ${product?.pincode}`}
                    >
                      Pincode: {product?.pincode}
                    </span>
                  ) : null}
                </div>
                {isString(product?.access) ? (
                  <span className="siteAccess">
                    {textProperties.access} {product?.access}
                  </span>
                ) : null}
                {" · "}
                {isString(product?.siteType) ? (
                  <span
                    className="siteType"
                    title={`Site Type: ${product?.siteType}`}
                  >
                    {textProperties.siteType} {product?.siteType}
                  </span>
                ) : null}

                {isString(product?.propertyType) ? (
                  <>
                    {" "}
                    <span
                      className="propertyType"
                      style={{ fontSize: "18px", margin: "0 5px 0 5px" }}
                    >
                      {" · "}
                    </span>
                    <span className="propertyType">
                      {textProperties.propertyType}{" "}
                      {titleCase(product?.propertyType.replace(/_/g, " "))}
                    </span>
                  </>
                ) : null}
              </div>
              {isString(product?.propertyType) ? (
                <span className="siteAccess propertyTypeMobileView">
                  {" "}
                  {textProperties.propertyType}{" "}
                  {titleCase(product?.propertyType.replace(/_/g, " "))}
                </span>
              ) : null}
              <hr className="siteDetailsHR" />
              <h2 className="nearbyFacilities">
                {textProperties.nearbyFacilities}
              </h2>
              <div className="siteFacilities">
                {isStringOrArray(product?.facilities) ? (
                  <div
                    className="nearbyFacilities"
                    title={`Nearby facilities: ${product?.facilities}`}
                  >
                    {titleCase(
                      product?.facilities
                        .join(", ")
                        .toString()
                        .replace(/_/g, " ")
                    )}
                  </div>
                ) : null}

                {/* <div className="nearbyFacilities">nearby facilities</div> */}
              </div>
              <h2 className="aboutSite">{textProperties.aboutSite}</h2>
              <div className="siteAboutData">
                {user?.emailVerified
                  ? product?.about
                    ? product.about
                    : textProperties.aboutSiteUnavailable
                  : textProperties.aboutNoAccess}
              </div>
              <hr className="siteDetailsHR" />

              <div className="siteOtherDetails">
                <h2 className="whatThisPlaceOffers">What this place offers</h2>
                <section className="grid">
                  {product?.parkingSupport === true || false ? (
                    <div
                      className="cell"
                      title={`Parking Support: ${
                        product?.parkingSupport
                          ? textProperties.parkingSupportTrue
                          : textProperties.parkingSupportFalse
                      }
                      `}
                    >
                      {textProperties.parkingSupport}{" "}
                      {product?.parkingSupport === true ? "Yes" : "No"}
                    </div>
                  ) : null}

                  {isNumber(product?.availableLoad) ? (
                    <div
                      className="cell"
                      title={`Available load: ${product?.availableLoad} kW`}
                    >
                      {" "}
                      Avl Load:{" "}
                      {product?.availableLoad.toString().length > 4
                        ? product?.availableLoad.toString().substring(0, 4) +
                          "..."
                        : product?.availableLoad}{" "}
                      kW
                    </div>
                  ) : null}

                  {isNumber(product?.numberOfParking) ? (
                    <div
                      className="cell"
                      title={`Number of parkings: ${product?.numberOfParking}`}
                    >
                      {" "}
                      {textProperties.parking}{" "}
                      {product?.numberOfParking.toString().length > 6
                        ? product?.numberOfParking.toString().substring(0, 6) +
                          "..."
                        : product?.numberOfParking}{" "}
                    </div>
                  ) : null}

                  {isStringOrArray(product?.vehicleSuitability) ? (
                    <div
                      className="cell"
                      title={`Suitable for: ${product?.vehicleSuitability}`}
                    >
                      {" "}
                      {textProperties.suitableFor}{" "}
                      {product?.vehicleSuitability.join(", ")}
                    </div>
                  ) : null}

                  {isNumber(product?.area) ? (
                    <div
                      className="cell"
                      title={`Area: ${product?.area} sq. ft.`}
                    >
                      {" "}
                      {textProperties.area}{" "}
                      {parseFloat(
                        product?.area.toString().substring(0, 6) + "..."
                      ).toFixed(1)}{" "}
                      ft<sup>2</sup>
                    </div>
                  ) : null}

                  {isNumber(product?.views) ? (
                    <div
                      className="cell"
                      title={`No. of views: ${product?.views}`}
                    >
                      {" "}
                      {textProperties.views}{" "}
                      {product?.views.toString().length > 6
                        ? product?.views.toString().substring(0, 6) + "..."
                        : product?.views}
                    </div>
                  ) : null}
                </section>
              </div>
            </div>
            <div className="rightside">
              <div className="ownerNameNumber">
                {product?.ownerName || product?.ownerContact ? (
                  <span className="purchasedLogo">Purchased</span>
                ) : product?.availableForSale ? (
                  <span className="purchasedLogo">Buy to view</span>
                ) : (
                  <span className="purchasedLogo">Sold out</span>
                )}
                <span className="ownerDetails">Owner Details:</span>
                <span className="sameline">
                  {" "}
                  Name:{" "}
                  <p className="fadedMedium">
                    {product?.ownerName
                      ? product.ownerName
                      : "Access Restricted"}
                  </p>
                </span>
                <span className="sameline">
                  Contact:{" "}
                  <p className="fadedMedium">
                    {product?.ownerContact
                      ? product.ownerContact
                      : "Access Restricted"}
                  </p>
                </span>
              </div>
              <div className="sitePricingCard">
                <h2 className="totalPrice">
                  {textProperties.totalPrice}{" "}
                  {isNumberOrFloat(product.price?.inclTax)
                    ? parseFloat(product.price?.inclTax).toFixed(2)
                    : "No Access"}
                </h2>
                <div className="sitePrice">
                  {product?.price ? (
                    <span
                      className="priceInNumber"
                      title={`Price: ${product.price?.inclTax}`}
                    >
                      {textProperties.price} ₹{" "}
                      {isNumberOrFloat(product.price?.inclTax)
                        ? parseFloat(product.price?.exclTax).toFixed(2)
                        : "No Access"}
                    </span>
                  ) : (
                    <span
                      className="priceInNumber"
                      title={textProperties.noPrice}
                    >
                      {/* remvove it */}
                      {textProperties.price} Unavailable
                    </span>
                  )}
                  <span className="priceDetail">
                    {textProperties.tax}
                    {isNumberOrFloat(product.price?.inclTax)
                      ? parseFloat(
                          product.price?.inclTax - product.price?.exclTax
                        ).toFixed(2)
                      : ""}{" "}
                    {isNumberOrFloat(product.price?.inclTax) ? (
                      <>
                        (GST{" "}
                        {(
                          parseFloat(
                            (product.price?.inclTax - product.price?.exclTax) /
                              product.price?.exclTax
                          ) * 100
                        ).toFixed(2)}
                        %)
                      </>
                    ) : null}
                  </span>
                </div>
                {user?.emailVerified ? (
                  <>
                    {product.availableForSale ? (
                      <Button
                        variant="contained"
                        className="buynow-btn"
                        onClick={() => addItemToCart(product?.id)}
                      >
                        {textProperties.buynow}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="buynow-btn soldout"
                      >
                        {textProperties.soldout}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {user ? (
                      <Button
                        variant="contained"
                        className="buynow-btn"
                        style={{ backgroundColor: "gray" }}
                        onClick={() =>
                          notify(textProperties.notify.verifyEmailToBuy)
                        }
                      >
                        {textProperties.buynow}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="buynow-btn"
                        style={{ backgroundColor: "gray" }}
                        onClick={() =>
                          notify(textProperties.notify.signinToBuy)
                        }
                      >
                        {textProperties.buynow}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mobilePriceCard">
            <div className="totalPrice">
              {product?.price ? (
                <span
                  className="priceInNumber"
                  title={`Price: ${product.price?.inclTax}`}
                >
                  {textProperties.totalPrice} ₹{" "}
                  {parseFloat(product.price?.inclTax).toFixed(2)}
                </span>
              ) : (
                <span className="priceInNumber" title={textProperties.noPrice}>
                  {textProperties.totalPrice} Unavailable
                </span>
              )}
              <span className="priceDetail">
                {textProperties.totalBeforeTax}
                {isNumberOrFloat(product.price?.inclTax) ? (
                  <>₹ {parseFloat(product.price?.exclTax).toFixed(2)}</>
                ) : (
                  ""
                )}
                {isNumberOrFloat(product.price?.inclTax) ? (
                  <>
                    + GST ₹
                    {parseFloat(
                      product.price?.inclTax - product.price?.exclTax
                    ).toFixed(2)}{" "}
                    (
                    {(
                      parseFloat(
                        (product.price?.inclTax - product.price?.exclTax) /
                          product.price?.exclTax
                      ) * 100
                    ).toFixed(2)}
                    %)
                  </>
                ) : null}
              </span>
            </div>
            {user?.emailVerified ? (
              <>
                {product.availableForSale === true ? (
                  <Button
                    variant="contained"
                    className="buynow-btn"
                    onClick={() => addItemToCart(product?.id)}
                  >
                    {textProperties.buynow}
                  </Button>
                ) : (
                  <Button variant="contained" className="buynow-btn soldout">
                    {textProperties.soldout}
                  </Button>
                )}
              </>
            ) : (
              <>
                {user ? (
                  <Button
                    variant="contained"
                    className="buynow-btn"
                    style={{ backgroundColor: "gray" }}
                    onClick={() =>
                      notify(textProperties.notify.verifyEmailToBuy)
                    }
                  >
                    {textProperties.buynow}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="buynow-btn"
                    style={{ backgroundColor: "gray" }}
                    onClick={() => notify(textProperties.notify.signinToBuy)}
                  >
                    {textProperties.buynow}
                  </Button>
                )}
              </>
            )}
          </div>
          <div className="footerElement"></div>
        </>
      ) : (
        <Shimmer />
      )}
      <div className="footerElement">
        <Footer />
      </div>
    </div>
  );
}
