import { getAuth } from "firebase/auth";
import { favourite_URL } from "../utils/constants";

export async function favouriteActions(siteId) {
  const auth = getAuth();
  const user = auth.currentUser;

  try {
    let apiUrl = favourite_URL + siteId;
    const refreshedUser = await user.getIdToken();
    console.log(apiUrl);
    const headers = {
      Authorization: `Bearer ${refreshedUser}`,
      accept: "application/json",
    };

    const response = await fetch(apiUrl, {
      method: "POST",
      headers,
    });

    if (!response.ok) {
      throw new Error("Favourite action failed");
    }

    console.log("Successfully changed favourite");
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
}
