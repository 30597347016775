import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import "./styles.css";
import SignIn from "../Authentication/Signin";
import LogoutButton from "../Authentication/Signout";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { useGlobalState } from "../GlobalContext";
import { toast } from "react-toastify";
import textProperties from "../utils/textProperties";
import PurchasedSites from "../PurchasedSites/PurchasedSites";

// import { WidthFull } from "@mui/icons-material";
// import Toast from "../Authentication/Toast";

export default function PositionedMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState(null);
  const [openIt, setOpen] = useState(false);
  const { updateOpenPurchased, openPurchased } = useGlobalState();

  const open = Boolean(anchorEl);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });

    return () => unsubscribe();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const notify = (toastMessage) => {
    toast.error(toastMessage, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
    });
  };

  const handlePurchased = () => {
    setOpen(!openIt);
    updateOpenPurchased(!openPurchased);
    handleClose();
  };

  return (
    <div>
      <div
        className="menu-container"
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {user ? (
          <span className="MenubarIcon">
            <MenuRoundedIcon />
            {user?.displayName === null ? (
              <AccountCircleIcon />
            ) : (
              <Avatar
                name={user?.displayName}
                round={true}
                size="25"
                textSizeRatio={2}
                color="#ea5355"
                style={{ marginBottom: "17px" }}
              />
            )}
          </span>
        ) : (
          <span className="MenubarIcon">
            <MenuRoundedIcon />
            <AccountCircleIcon />
          </span>
        )}
      </div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "1rem",
            marginTop: "0.5rem",
            boxShadow: "0 0px 5px 0 rgba(67, 66, 66, 0.2)",
            minWidth: "200px",
            overflow: "visible",
          },
        }}
        disableScrollLock={true}
      >
        {!user ? (
          <MenuItem className="signin-menubar">
            <SignIn value={"Sign in"} />
          </MenuItem>
        ) : (
          <MenuItem className="signup">
            <LogoutButton />
          </MenuItem>
        )}
        {!user ? (
          <Link to="/signup">
            <MenuItem onClick={handleClose} className="signup">
              {textProperties.signUp}
            </MenuItem>
          </Link>
        ) : null}
        <hr />
        {user ? (
          <Link to="/profile">
            <MenuItem onClick={handleClose} className="cpoassistprofile">
              {textProperties.profile}
            </MenuItem>
          </Link>
        ) : (
          <SignIn
            value={
              <MenuItem
                className="cpoassistprofile"
                onClick={() =>
                  notify(textProperties.notify.signinToViewProfile)
                }
              >
                {textProperties.profile}
              </MenuItem>
            }
          />
        )}
        {user ? (
          <Link to="/cart">
            <MenuItem onClick={handleClose} className="cart">
              {textProperties.myCart}{" "}
            </MenuItem>
          </Link>
        ) : (
          <SignIn
            value={
              <MenuItem
                onClick={() => notify(textProperties.notify.signinToViewCart)}
                className="cart cpoassistprofile"
              >
                {textProperties.myCart}{" "}
              </MenuItem>
            }
          />
        )}
        {user?.emailVerified && (
          <MenuItem
            onClick={() => handlePurchased(!openIt)}
            className="cart cpoassistprofile"
          >
            {textProperties.purchasedSites}{" "}
          </MenuItem>
        )}
        {/* <hr />
        <Link to="/admin-portal">
          <MenuItem onClick={handleClose} className="helpcenter">
            {textProperties.adminPortal}
          </MenuItem>
        </Link> */}
      </Menu>
      {openPurchased && <PurchasedSites />}
    </div>
  );
}
