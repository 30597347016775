import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
// import firebase from'firebase/app';
// import { getAnalytics } from "firebase/analytics";
// import { getRemoteConfig } from 'firebase/remote-config';

// const firebaseConfig = {
//   apiKey: "AIzaSyCZ2Ga4vSMFJkl0q5UfwLzKdDAiWVKMhyo",
//   authDomain: "html-integration.firebaseapp.com",
//   projectId: "html-integration",
//   storageBucket: "html-integration.appspot.com",
//   messagingSenderId: "275088580884",
//   appId: "1:275088580884:web:df01cc0ef839a073726201",
//   measurementId: "G-PEQB3DXKPW"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAye62-uZeZNP8a8-Q1IfYQaSIRKIoIJLc",
  authDomain: "test-site-listing-f4325.firebaseapp.com",
  projectId: "test-site-listing-f4325",
  storageBucket: "test-site-listing-f4325.appspot.com",
  messagingSenderId: "150589122087",
  appId: "1:150589122087:web:7d0ae95de2d66289715002",
  measurementId: "G-L7F206VFFF"
};

// Initialize Firebase
const App = initializeApp(firebaseConfig);
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(App);
export default App;

// export const remoteConfig = firebase.remoteConfig();
// const remoteConfigInstance = getRemoteConfig(App);
// export { remoteConfigInstance };
