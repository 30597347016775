import { getAuth } from "firebase/auth";
import { useGlobalState } from "../components/GlobalContext";
import { useEffect } from "react";
import {
  apiQueryAuthenticated,
  apiQueryPublic,
} from "../components/utils/constants";

function ApplyFiltersToApi(queryString) {
  const { updateGlobalData, selectedFilters, updateTotalSitesCount } =
    useGlobalState();
  const auth = getAuth();

  let apiUrl = apiQueryPublic + queryString; // Concatenate here
  const user = auth.currentUser;
  if (user?.emailVerified) {
    apiUrl = apiQueryAuthenticated + queryString;
  }

  async function getRefreshedToken() {
    try {
      if (user) {
        // const refreshedToken = await user.getIdToken(/* forceRefresh */ true);
        const refreshedToken = await user.getIdToken();
        return refreshedToken;
      }
      return null;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const refreshedToken = await getRefreshedToken();
        const headers = {
          accept: "application/json",
        };

        if (refreshedToken) {
          headers.Authorization = `Bearer ${refreshedToken}`;
        }

        const response = await fetch(apiUrl, {
          method: "GET",
          headers,
        });

        if (response.ok) {
          const data = await response.json();
          const totalCount = response.headers.get("x-total-count");
          updateGlobalData(data?.data);
          updateTotalSitesCount(totalCount);
        } else {
          console.error("Error fetching the data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedFilters, user]);
}

function TransformFiltersToQueryString() {
  const { selectedFilters } = useGlobalState();
  const queryString = selectedFilters
    .map((filter) => {
      const [filterName, values] = filter.split(": ");
      if (
        filterName === "limit" ||
        filterName === "cities" ||
        filterName === "propertyTypes" ||
        filterName === "pincode" ||
        filterName === "sortType"
      ) {
        const keyValuePairs = values
          .split(", ")
          .map((value) => `${filterName}=${value}`);
        return keyValuePairs.join("&");
      }
      return "";
    })
    .filter(Boolean)
    .join("&");
  ApplyFiltersToApi(queryString);
  return queryString;
}

export default TransformFiltersToQueryString;
