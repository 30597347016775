import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGlobalState } from "../GlobalContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import {
//   initializeOfflineHandler,
//   removeOfflineHandler,
//   storeLastLocation,
// } from "../utils/offilneHandler";
import { toast } from "react-toastify";
import SignIn from "../Authentication/Signin";
import PositionedMenu from "./MenuBar";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import logo from "../../assets/logo/logo.png";
import logoShort from "../../assets/logo/logoShort.png";
// import Darkmode from "../Darkmode/Darkmode";
import "./styles.css";
import textProperties from "../utils/textProperties";

function Header() {
  const { cartNumber, updateCartNumber } = useGlobalState();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });
    updateCartNumber(localStorage.getItem("cartNumber"));

    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   initializeOfflineHandler();
  //   return () => {
  //     removeOfflineHandler();
  //     storeLastLocation();
  //   };
  // }, []);

  const notify = (toastMessage) => {
    toast.error(toastMessage, {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 3000,
    });
  };

  return (
    <>
      <div className="navbar">
        <Link to="/">
          <img src={logo} alt="CPO Assist logo" className="navbar-logo" />
          {window.location.pathname === "/" ? (
            <img src={logoShort} alt="CPO Assist logo" className="logoShort" />
          ) : (
            <img src={logo} alt="CPO Assist logo" className="logoShort" />
          )}
        </Link>
        <div className="header-end">
          {/* <Darkmode /> */}
          <div className="cart-icon">
            {/* <div className="darkModeHidden">
              <Darkmode/>
            </div> */}
            {user ? (
              <>
                <Link to="/cart">
                  <ShoppingCartRoundedIcon className="shopping-cart-icon" />
                </Link>

                {cartNumber > 0 ? (
                  <span className="cart-number">{cartNumber}</span>
                ) : (
                  <span className="cart-number">0</span>
                )}
              </>
            ) : (
              <SignIn
                value={
                  <ShoppingCartRoundedIcon
                    className="shopping-cart-icon"
                    onClick={() =>
                      notify(textProperties.notify.signinToViewCart)
                    }
                  />
                }
              />
            )}
          </div>
          <PositionedMenu />
        </div>
      </div>
      <div className="main-nav-hr"></div>
      {/* <SimpleBottomNavigation /> */}
    </>
  );
}

export default Header;
