import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toast(toastText) {
  const [isToastDisplayed, setIsToastDisplayed] = React.useState(false);
  const toastMessage = toastText;
  console.log(toastMessage);
  if (!isToastDisplayed) {
    const notify = () => {
      toast.error(toastMessage, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
        onChange: () => setIsToastDisplayed(false),
      });
      setIsToastDisplayed(true);
    };
    notify();
  }

  return (
    <div>
      {" "}
      <ToastContainer theme="light" autoClose={3000} />
    </div>
  );
}

export default Toast;



