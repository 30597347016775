import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import { fetchProfileData } from "../utils/constants";
import LoadingModal from "../utils/LoadingModal";
import SecondNavbar from "../Header/SecondNavbar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
// import FormHelperText from "@mui/material/FormHelperText";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "./Signin";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./styles.css";
import textProperties from "../utils/textProperties";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="/">
        CPO ASSIST
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isRoleFocused, setIsRoleFocused] = useState(false);
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isMobileNumberFocused, setIsMobileNumberFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && window.location.pathname === "/signup") {
      navigate("/");
    }
  }, [user]);

  const isValidRole = (role) => {
    return ["cpo", "guest", "lcp"].includes(role);
  };

  const handleMobileNumberChange = (event) => {
    const inputValue = event.target.value;
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) && inputValue.length <= 10) {
      handleChange(event);
    }
  };

  const isMobileNumberValid = (mobileNumber) => {
    const mobileRegex = /^[6789]\d{9}$/;
    return mobileRegex.test(mobileNumber);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()_+{}|:"<>?]).{8,}$/;
    return passwordRegex.test(password);
  };

  const isValidName = (firstName) => {
    const firstNameRegex = /^[A-Za-z]{1,25}$/;
    return firstNameRegex.test(firstName);
  };

  const handleMobileNumberFocus = () => {
    setIsMobileNumberFocused(true);
  };
  const handleEmailFocus = () => {
    setIsEmailFocused(true);
  };
  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };
  const handleFirstNameFocus = () => {
    setIsFirstNameFocused(true);
  };
  const handleLastNameFocus = () => {
    setIsLastNameFocused(true);
  };
  const handleRoleFocus = () => {
    setIsRoleFocused(true);
  };

  const areAllFieldsValid = () => {
    const isRoleValid = isValidRole(signUpData.role);
    const isMobileNumValid = isMobileNumberValid(signUpData?.mobileNumber);
    const isEmailValid = isValidEmail(signUpData.email);
    const isPasswordValid = isValidPassword(password);
    const isNameValid = isValidName(signUpData.firstName);
    return (
      isRoleValid &&
      isMobileNumValid &&
      isEmailValid &&
      isPasswordValid &&
      isNameValid
    );
  };

  const [signUpData, setSignUpData] = useState({
    role: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    companyName: "",
    gstNumber: "",
    panNumber: "",
    companyAddress: "",
    companyEmail: "",
    phoneNumber: "",
  });

  const notify = (toastMessage) => {
    toast.error(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 7000,
    });
  };

  // console.log(signUpData);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    if (areAllFieldsValid()) {
      e.preventDefault();
      setIsModalOpen(true);
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          signUpData.email,
          password
        );

        // console.log(signUpData);
        const user = userCredential.user;

        try {
          let apiUrl = fetchProfileData;
          const refreshedUser = await user.getIdToken(/* forceRefresh */ true);
          const headers = {
            Authorization: `Bearer ${refreshedUser}`,
            accept: "application/json",
            "Content-Type": "application/json",
          };

          const response = await fetch(apiUrl, {
            method: "PATCH",
            headers,
            body: JSON.stringify(signUpData),
          });

          if (!response.ok) {
            throw new Error("Failed to store data in Firebase");
          }
          console.log("Data stored successfully!");
        } catch (error) {
          console.error("Error:", error);
        }

        await sendEmailVerification(user);
        console.log("Verification email sent to " + user.email);
        navigate("/");
        notify(textProperties.notify.signupSuccess);
      } catch (error) {
        const errorCode = error.code;
        // const errorMessage = error.message;
        setIsModalOpen(false);
        setErrorMessage(textProperties?.firebaseError?.[errorCode]);
      }
    } else {
      e.preventDefault();
      notify(textProperties.notify.mandatoryFieldError);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignUpData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <ToastContainer />
      <LoadingModal open={isModalOpen} text={"Signing up..."}/>
      <div className="signup-container">
        <SecondNavbar />
        {/* <Header /> */}
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "rgb(234, 83, 85)" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {textProperties.signUp}
              </Typography>
              <Box
                component="form"
                id="signup"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      error={isRoleFocused && !isValidRole(signUpData.role)}
                    >
                      <InputLabel id="userRole">
                        {textProperties.role} *
                      </InputLabel>
                      <Select
                        labelId="userRole"
                        id="userRole"
                        name="role"
                        autoFocus
                        value={signUpData.role}
                        label={isRoleFocused ? "Role *" : "Role *"}
                        onChange={handleChange}
                        onFocus={handleRoleFocus}
                        // onBlur={handleRoleBlur}
                      >
                        <MenuItem value="">
                          <em>{textProperties.selectRole}</em>
                        </MenuItem>
                        <MenuItem value={"cpo"}>
                          {textProperties.cpoRole}
                        </MenuItem>
                        <MenuItem value={"guest"}>
                          {textProperties.guestRole}
                        </MenuItem>
                        <MenuItem value={"lcp"}>
                          {textProperties.lcpRole}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label={
                        isFirstNameFocused
                          ? textProperties.firstName
                          : textProperties.firstName
                      }
                      value={signUpData.firstName || ""}
                      onChange={handleChange}
                      onFocus={handleFirstNameFocus}
                      // onBlur={handleFirstNameBlur}
                      error={
                        isFirstNameFocused && !isValidName(signUpData.firstName)
                      }
                      helperText={
                        isFirstNameFocused && !isValidName(signUpData.firstName)
                          ? textProperties.userDetails.invalidFirstName
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label={
                        isLastNameFocused
                          ? textProperties.lastName
                          : textProperties.lastName
                      }
                      name="lastName"
                      autoComplete="family-name"
                      value={signUpData.lastName || ""}
                      onChange={handleChange}
                      onFocus={handleLastNameFocus}
                      // onBlur={handleLastNameBlur}
                      error={
                        isLastNameFocused && !isValidName(signUpData.lastName)
                      }
                      helperText={
                        isLastNameFocused && !isValidName(signUpData.lastName)
                          ? textProperties.userDetails.invalidLastName
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* Other form fields */}
                    <TextField
                      required
                      fullWidth
                      id="mobileNumber"
                      label={textProperties.mobileNumber}
                      name="mobileNumber"
                      autoComplete="phone"
                      type="tel"
                      value={signUpData.mobileNumber}
                      onChange={handleMobileNumberChange}
                      onFocus={handleMobileNumberFocus}
                      // onBlur={handleMobileNumberBlur}
                      error={
                        isMobileNumberFocused &&
                        !isMobileNumberValid(signUpData.mobileNumber)
                      }
                      helperText={
                        isMobileNumberFocused &&
                        !isMobileNumberValid(signUpData.mobileNumber.length)
                          ? textProperties.userDetails.invalidMobile
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label={
                        isEmailFocused
                          ? textProperties.emailAddress
                          : textProperties.emailAddress
                      }
                      name="email"
                      autoComplete="email"
                      value={signUpData?.email}
                      onChange={handleChange}
                      onFocus={handleEmailFocus}
                      // onBlur={handleEmailBlur}
                      error={isEmailFocused && !isValidEmail(signUpData?.email)}
                      helperText={
                        isEmailFocused && !isValidEmail(signUpData?.email)
                          ? textProperties.userDetails.invalidEmail
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label={
                        isPasswordFocused
                          ? textProperties.password
                          : textProperties.password
                      }
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={handlePasswordFocus}
                      error={isPasswordFocused && !isValidPassword(password)}
                      helperText={
                        isPasswordFocused && !isValidPassword(password)
                          ? textProperties.userDetails.invalidPassword
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                {/* Error message */}
                {errorMessage && (
                  <p
                    style={{ color: "red", textAlign: "center", margin: "1px" }}
                  >
                    {errorMessage}
                  </p>
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={toggleDropdown}
                  startIcon={
                    <ExpandMoreIcon style={{ color: "rgb(234, 83, 85)" }} />
                  }
                  sx={{ mt: 2 }}
                  style={{
                    color: "rgb(234, 83, 85)",
                    borderColor: "rgb(234, 83, 85)",
                  }}
                >
                  {textProperties.additionalDetails}
                </Button>

                <Collapse in={isDropdownOpen}>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel htmlFor="companyName">
                      {textProperties.companyName}
                    </InputLabel>
                    <Input
                      id="companyName"
                      name="companyName"
                      value={signUpData.companyName}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel htmlFor="gstNumber">
                      {textProperties.gstNumber}
                    </InputLabel>
                    <Input
                      id="gstNumber"
                      name="gstNumber"
                      value={signUpData.gstNumber}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel htmlFor="panNumber">
                      {textProperties.panNumber}
                    </InputLabel>
                    <Input
                      id="panNumber"
                      name="panNumber"
                      value={signUpData.panNumber}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel htmlFor="companyAddress">
                      {textProperties.companyAddress}
                    </InputLabel>
                    <Input
                      id="companyAddress"
                      name="companyAddress"
                      value={signUpData.companyAddress}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel htmlFor="companyEmail">
                      {textProperties.companyEmail}
                    </InputLabel>
                    <Input
                      id="companyEmail"
                      name="companyEmail"
                      value={signUpData.companyEmail}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel htmlFor="phoneNumber">
                      {textProperties.phoneNumber}
                    </InputLabel>
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      value={signUpData.phoneNumber}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Collapse>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                  style={{
                    backgroundColor: "rgb(234, 83, 85)",
                    color: "white",
                  }}
                >
                  {textProperties.signUp}
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item></Grid>
                </Grid>
              </Box>
            </Box>
            <MenuItem className="signin-menubar" sx={{ ml: -2 }}>
              {textProperties.alreadyHaveAccount}
              <SignIn value={"Sign in"} />
            </MenuItem>
            <Copyright sx={{ mt: 5, mb: 2 }} />
            {/* <Footer sx={{minWidth: "100vh"}}/> */}
          </Container>
        </ThemeProvider>
      </div>
    </>
  );
}
