import { getAuth } from "firebase/auth";
import { cart_URL } from "../utils/constants";

export async function getMyCart() {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user.emailVerified) {
    try {
      let apiUrl = cart_URL;
      // console.log("Fetching my cart: " + apiUrl);
      const refreshedUser = await user.getIdToken();

      const headers = {
        Authorization: `Bearer ${refreshedUser}`,
        accept: "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        throw new Error("Failed to get cart data");
      }

      const { data } = await response.json();
      // console.log(data?.sites);
      return data;
    } catch (error) {
      console.error("Error fetching cart:", error);
      throw error;
    }
  }
}
